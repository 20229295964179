<template>
</template>

<script>
export default {
  setup() {
    console.log(11)
    return {

    };
  }
};
</script>

<style lang="less">

</style>
